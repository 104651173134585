body {
  height: 100%;
}

.firebaseui-idp-list {
  margin-top: 12px !important;
}

.ant-table-filter-column {
  margin: -16px 0 !important;
}

@media only screen and (max-width: 768px) {

  .ant-layout-sider, .non-mobile  {
    display: none;
  }

  .no-padding-mobile {
    padding: 0 !important;
  }
}

.ant-table-content {
  overflow-x: auto;
}

.popup {
  background-clip: padding-box;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  left: 0px;
  list-style-type: none;
  margin: 0;
  outline: none;
  padding: 0;
  position: fixed;
  text-align: left;
  top: 0px;
  overflow: hidden;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.popup li {
  clear: both;
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  margin: 0;
  padding: 5px 12px;
  transition: all .3s;
  white-space: nowrap;
  -webkit-transition: all .3s;
}

.popup li:hover {
  background-color: #e6f7ff;
}

.popup li > i {
  margin-right: 8px;
}
